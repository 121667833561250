import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import logo from "../images/one-line-gg-logo.png"
import Nav from "./nav"

const Header = ({ siteTitle }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const handleAnchorClick = e => {
    let el = document.querySelector(e.target.hash)
    el &&
      el.scrollIntoView({
        behavior: "smooth",
      })
    setMenuOpen(false)
    setTimeout(() => {
      el && el.focus()
    }, 250)
  }

  const handleAnchorKeydown = e => {
    if (e.key === " " || e.key === "Enter") {
      e.preventDefault()
      handleAnchorClick(e)
    }
  }

  return (
    <>
      <header className={menuOpen ? "full-screen" : ""}>
        <div className="brand">
          <Link
            to="/"
            style={{
              textDecoration: `none`,
            }}
            title={siteTitle}
          >
            <img src={logo} alt="The Gothic Guild" />
          </Link>
        </div>
        <Nav
          handleAnchorClick={handleAnchorClick}
          handleAnchorKeydown={handleAnchorKeydown}
        />
        <div className="menu-opener">
          <button className="menu-btn" onClick={() => setMenuOpen(!menuOpen)}>
            Menu
          </button>
        </div>
      </header>
      {menuOpen && (
        <Nav
          handleAnchorClick={handleAnchorClick}
          handleAnchorKeydown={handleAnchorKeydown}
        />
      )}
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
