import React from "react"
import PropTypes from "prop-types"

const Nav = ({ handleAnchorClick, handleAnchorKeydown }) => (
  <nav>
    <a
      href="#about"
      onClick={handleAnchorClick}
      onKeyDown={handleAnchorKeydown}
    >
      About
    </a>
    <a
      href="#fundraising"
      onClick={handleAnchorClick}
      onKeyDown={handleAnchorKeydown}
    >
      Fundraising
    </a>
    <a
      href="#history"
      onClick={handleAnchorClick}
      onKeyDown={handleAnchorKeydown}
    >
      History
    </a>
  </nav>
)

Nav.propTypes = {
  handleAnchorClick: PropTypes.func,
  handleAnchorKeydown: PropTypes.func,
}

export default Nav
